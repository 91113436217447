#availability {
  background-color: black;
  color: white;
}

.availability-title {
  /* text-align: center;
  font-size: 3rem;
  padding-top: 15%;
  padding-bottom: 15%; */
}

.availability-map {
  width: 90vw;
  display: block;
  margin: auto auto;
  border-radius: 12px;
}

.availability-container {
  width: 90vw;
  place-items: center;
  margin: 10% auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  row-gap: 10%;
}

.availability-container a {
  text-decoration: none;
  color: white;
}

.availability-street {
  font-size: 1.4rem;
  grid-column: 1/4;
  grid-row: 1;
}

.availability-street a:hover {
  color: white;
}

.availability-phone {
  font-size: 1.3rem;
  grid-column: 1/4;
  grid-row: 2;
}

.availability-facebook {
  grid-column: 1;
  grid-row: 3;
  font-size: 1.3rem;
  place-items: center;
}

.availability-instagram {
  grid-column: 2;
  grid-row: 3;
  font-size: 1.3rem;
}

.availability-youtube {
  grid-column: 3;
  grid-row: 3;
  font-size: 1.3rem;
}

.icon-street {
  position: relative;
  bottom: 0.3vh;
  width: 20px;
}

.icon-phone {
  position: relative;
  bottom: 0.2vh;
  width: 20px;
}
.icon-facebook {
  position: relative;
  bottom: 0.3vh;
  width: 20px;
}
.icon-instagram {
  position: relative;
  bottom: 0.1vh;
  left: 1px;
  width: 16px;
}
.icon-youtube {
  position: relative;
  width: 20px;
}

#contact {
  background-color: black;
}

.contact-title {
  /* text-align: center;
    font-size: 3rem;
    padding-top: 2%;
    padding-bottom: 15%; */
}

.contact-form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.contact-form input,
textarea {
  margin-bottom: 3%;
  padding: 1%;
  width: 85vw;
}

.contact-form input,
textarea:focus {
  outline: none;
}
.contact-form input {
  font-size: 1.4rem;
}

.contact-form textarea {
  resize: none;
  font-size: 1.4rem;
}

.display-status {
  opacity: 1;
}

.hide-status {
  opacity: 0;
}

.error {
  color: red;
}

.success {
  color: green;
}

.availability-facebook a:hover,
.availability-instagram a:hover,
.availability-youtube a:hover {
  color: white;
}

.contact-send-button {
  background-color: white;
  border: none;
  color: black;
  border-radius: 2px;
}

@media only screen and (max-width: 342px) {
  .availability-facebook span,
  .availability-instagram span,
  .availability-youtube span {
    display: none;
  }

  .availability-facebook,
  .availability-instagram,
  .availability-youtube {
    grid-column: 2;
  }

  .availability-facebook {
    justify-self: start;
  }

  .availability-instagram {
    justify-self: center;
  }

  .availability-youtube {
    justify-self: end;
  }

  .icon-facebook,
  .icon-instagram,
  .icon-youtube {
    position: unset;

    margin: 0;
  }

  .icon-facebook,
  .icon-youtube {
    width: 40px;
    height: 30px;
  }

  .icon-youtube {
    vertical-align: middle;
    height: 35px;
  }

  .icon-instagram {
    width: 25px;
  }

  .icon-phone {
    top: 0;
    left: 0;
  }

  .icon-street {
    top: 0;
    left: 0;
  }
}

@media only screen and (max-width: 326px) {
  .availability-street {
    font-size: 1rem;
  }

  .availability-phone {
    font-size: 0.8rem;
  }

  .icon-facebook,
  .icon-youtube {
    width: 20px;
  }

  .icon-instagram {
    width: 15px;
  }

  .icon-youtube {
    position: relative;
    top: 2px;
  }
}

@media only screen and (min-width: 650px) {
  .availability-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .availability-map {
    width: 50vw;
    margin-left: 3%;
  }

  .availability-container {
    width: unset;
    margin: 0;
    margin-left: 0%;
    row-gap: 0px;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(5, auto);
  }

  .availability-street,
  .availability-phone,
  .availability-facebook,
  .availability-instagram,
  .availability-youtube {
    justify-self: start;
  }

  .availability-street {
    grid-column: 1;
    grid-row: 1;
  }
  .availability-phone {
    grid-column: 1;
    grid-row: 2;
  }

  .availability-facebook {
    grid-column: 1;
    grid-row: 3;
  }
  .availability-instagram {
    grid-column: 1;
    grid-row: 4;
  }
  .availability-youtube {
    grid-column: 1;
    grid-row: 5;
  }
}

@media only screen and (min-width: 1000px) {
  .availability-street,
  .availability-phone,
  .availability-facebook,
  .availability-instagram,
  .availability-youtube {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1300px) {
  .availability-map {
    width: 40vw;
  }

  .contact-form input,
  textarea {
    width: 60vw;
    margin-bottom: 1.5%;
  }
}

@media only screen and (min-width: 1500px) {
  .availability-content-container {
    align-content: center;
    justify-content: space-evenly;
    margin: auto auto;
  }

  .availability-map {
    width: 30vw;
  }

  .availability-container {
    width: unset;
  }
}
