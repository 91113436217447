/* phone */
#welcome {
  background: url(/images/fokep_phone.jpg) no-repeat center;
  background-size: cover;
  height: 50vh;
  width: 100vw;
  padding-bottom: 0;
}

#welcome2 {
  display: grid;
  place-items: center;
  height: 50vh;
  background-color: #660033;
  padding-bottom: 0;
}
/* .welcome-image {
  width: 100vw;
  height: 50vh;
} */

.welcome-title {
  /* padding: 45% 0; */
  text-align: center;
  font-size: 2rem;
}

/* on rotate - phone */
@media only screen and (max-height: 420px) and (max-width: 830px) {
  #welcome {
    background: url(/images/fokep_phone.jpg) no-repeat top;
    background-size: cover;
    min-height: 120vh;
  }

  #welcome2 {
    display: grid;
    place-items: center;
    height: 50vh;
    background-color: #660033;
  }

  .welcome-title {
    padding-top: 5%;
    font-size: 2rem;
  }
}

/* on rotate - bigger phone */
@media only screen and (min-height: 375px) and (max-height: 420px) and (min-width: 775px) and (max-width: 896px) {
  #welcome {
    background: url(/images/fokep_phone.jpg) no-repeat top;
    background-size: cover;
  }
}

/*  tablet  */
@media only screen and (min-width: 420px) and (max-width: 1400px) and (min-height: 831px) and (max-height: 1400px) {
  #welcome {
    background: url(/images/fokep_phone.jpg) no-repeat center;
    background-size: cover;
  }

  .welcome-title {
    font-size: 4.5rem;
  }
}

/* on rotate - tablet */
@media only screen and (min-height: 421px) and (max-height: 1050px) and (min-width: 831px) and (max-width: 1400px) {
  #welcome {
    background: url(/images/fokep_phone.jpg) no-repeat;
    background-size: cover;
    background-position: 50% 25%;
    height: 100vh;
  }

  #welcome2 {
    display: grid;
    place-items: center;
    height: 50vh;
    background-color: #660033;
  }

  .welcome-title {
    font-size: 3.5rem;
  }
}

/* else (laptop, monitor) */
@media only screen and (min-width: 1401px) {
  #welcome {
    height: 100vh;
    background: url(/images/fokep.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 30%;
  }

  .welcome-title {
    font-size: 5rem;
  }
}

@media only screen and (max-height: 370px) {
  .welcome-title {
    font-size: 1rem;
  }
}
