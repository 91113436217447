/* phone */
#about {
  background-color: #660033;
}

.text-parent {
  display: grid;
  place-items: center;
  margin: 0 3%;
  padding: 5% 5%;
  background-color: rgba(0, 0, 0, 0.55);
}

.about-text {
  font-size: 1rem;
}

.about-text-paragraph {
  text-align: left;
  font-size: 1.4rem;
  font-weight: 800;
  margin-bottom: 1%;
  margin-top: 2%;
}
/* on rotate - phone */
@media only screen and (max-height: 420px) and (max-width: 830px) {
  .text-parent {
    margin: 0 1%;
    padding: 2% 2%;
  }
}

/* on rotate - bigger phone */
@media only screen and (min-height: 375px) and (max-height: 420px) and (min-width: 775px) and (max-width: 896px) {
}

/*  tablet  */
@media only screen and (min-width: 420px) and (max-width: 1400px) and (min-height: 831px) and (max-height: 1400px) {
  .about-title {
    font-size: 4rem;
  }

  .about-text {
    font-size: 1.5rem;
  }
}

/* on rotate - tablet */
@media only screen and (min-height: 421px) and (max-height: 1050px) and (min-width: 831px) and (max-width: 1400px) {
  .about-title {
    font-size: 4rem;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .about-text {
    font-size: 1.5rem;
  }
}

/* else (laptop, monitor) */
@media only screen and (min-width: 1401px) {
  .text-parent {
    padding: 2% 2%;
  }

  .about-text {
    font-size: 1.5rem;
  }
}

/* unnormal screen (glaxy fold) */
@media only screen and (max-height: 280px) and (max-width: 653px) {
  .about-text {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 157px) {
  .about-title {
    font-size: 1.5rem;
  }
}
