@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif !important;
}

html,
body {
  color: white !important;
  /* overflow-x: hidden; */
}

section {
  padding-bottom: 10%;
}

.section-titles {
  font-size: 2.5rem;
  padding-top: 15%;
  padding-bottom: 5%;
  text-align: center;
}

@media only screen and (max-width: 222px) {
  .section-titles {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 166px) {
  .section-titles {
    font-size: 1.6rem;
  }

  body {
    overflow-x: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .section-titles {
    font-size: 2.8rem;
  }
}

@media only screen and (min-width: 1400px) {
  .section-titles {
    font-size: 3rem;
  }
}

@media only screen and (min-width: 1600px) {
  .section-titles {
    font-size: 3.5rem;
  }
}

#availability {
  background-color: black;
  color: white;
}

.availability-title {
  /* text-align: center;
  font-size: 3rem;
  padding-top: 15%;
  padding-bottom: 15%; */
}

.availability-map {
  width: 90vw;
  display: block;
  margin: auto auto;
  border-radius: 12px;
}

.availability-container {
  width: 90vw;
  place-items: center;
  margin: 10% auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 10%;
  row-gap: 10%;
}

.availability-container a {
  text-decoration: none;
  color: white;
}

.availability-street {
  font-size: 1.4rem;
  grid-column: 1/4;
  grid-row: 1;
}

.availability-street a:hover {
  color: white;
}

.availability-phone {
  font-size: 1.3rem;
  grid-column: 1/4;
  grid-row: 2;
}

.availability-facebook {
  grid-column: 1;
  grid-row: 3;
  font-size: 1.3rem;
  place-items: center;
}

.availability-instagram {
  grid-column: 2;
  grid-row: 3;
  font-size: 1.3rem;
}

.availability-youtube {
  grid-column: 3;
  grid-row: 3;
  font-size: 1.3rem;
}

.icon-street {
  position: relative;
  bottom: 0.3vh;
  width: 20px;
}

.icon-phone {
  position: relative;
  bottom: 0.2vh;
  width: 20px;
}
.icon-facebook {
  position: relative;
  bottom: 0.3vh;
  width: 20px;
}
.icon-instagram {
  position: relative;
  bottom: 0.1vh;
  left: 1px;
  width: 16px;
}
.icon-youtube {
  position: relative;
  width: 20px;
}

#contact {
  background-color: black;
}

.contact-title {
  /* text-align: center;
    font-size: 3rem;
    padding-top: 2%;
    padding-bottom: 15%; */
}

.contact-form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.contact-form input,
textarea {
  margin-bottom: 3%;
  padding: 1%;
  width: 85vw;
}

.contact-form input,
textarea:focus {
  outline: none;
}
.contact-form input {
  font-size: 1.4rem;
}

.contact-form textarea {
  resize: none;
  font-size: 1.4rem;
}

.display-status {
  opacity: 1;
}

.hide-status {
  opacity: 0;
}

.error {
  color: red;
}

.success {
  color: green;
}

.availability-facebook a:hover,
.availability-instagram a:hover,
.availability-youtube a:hover {
  color: white;
}

.contact-send-button {
  background-color: white;
  border: none;
  color: black;
  border-radius: 2px;
}

@media only screen and (max-width: 342px) {
  .availability-facebook span,
  .availability-instagram span,
  .availability-youtube span {
    display: none;
  }

  .availability-facebook,
  .availability-instagram,
  .availability-youtube {
    grid-column: 2;
  }

  .availability-facebook {
    justify-self: start;
  }

  .availability-instagram {
    justify-self: center;
  }

  .availability-youtube {
    justify-self: end;
  }

  .icon-facebook,
  .icon-instagram,
  .icon-youtube {
    position: unset;

    margin: 0;
  }

  .icon-facebook,
  .icon-youtube {
    width: 40px;
    height: 30px;
  }

  .icon-youtube {
    vertical-align: middle;
    height: 35px;
  }

  .icon-instagram {
    width: 25px;
  }

  .icon-phone {
    top: 0;
    left: 0;
  }

  .icon-street {
    top: 0;
    left: 0;
  }
}

@media only screen and (max-width: 326px) {
  .availability-street {
    font-size: 1rem;
  }

  .availability-phone {
    font-size: 0.8rem;
  }

  .icon-facebook,
  .icon-youtube {
    width: 20px;
  }

  .icon-instagram {
    width: 15px;
  }

  .icon-youtube {
    position: relative;
    top: 2px;
  }
}

@media only screen and (min-width: 650px) {
  .availability-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .availability-map {
    width: 50vw;
    margin-left: 3%;
  }

  .availability-container {
    width: unset;
    margin: 0;
    margin-left: 0%;
    grid-row-gap: 0px;
    row-gap: 0px;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(5, auto);
  }

  .availability-street,
  .availability-phone,
  .availability-facebook,
  .availability-instagram,
  .availability-youtube {
    justify-self: start;
  }

  .availability-street {
    grid-column: 1;
    grid-row: 1;
  }
  .availability-phone {
    grid-column: 1;
    grid-row: 2;
  }

  .availability-facebook {
    grid-column: 1;
    grid-row: 3;
  }
  .availability-instagram {
    grid-column: 1;
    grid-row: 4;
  }
  .availability-youtube {
    grid-column: 1;
    grid-row: 5;
  }
}

@media only screen and (min-width: 1000px) {
  .availability-street,
  .availability-phone,
  .availability-facebook,
  .availability-instagram,
  .availability-youtube {
    font-size: 2rem;
  }
}

@media only screen and (min-width: 1300px) {
  .availability-map {
    width: 40vw;
  }

  .contact-form input,
  textarea {
    width: 60vw;
    margin-bottom: 1.5%;
  }
}

@media only screen and (min-width: 1500px) {
  .availability-content-container {
    align-content: center;
    justify-content: space-evenly;
    margin: auto auto;
  }

  .availability-map {
    width: 30vw;
  }

  .availability-container {
    width: unset;
  }
}

#event {
  background-color: #660033;
  
}

.event-container{
  display: grid;
  place-items: center;
  grid-template-columns: auto;
}

.event-card{
  border-top: 1px solid white;
  border-right: 1px solid white;
  width: 90vw;
  padding: 2% 1%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 0.5fr);
  margin-bottom: 2%;
}

.event-card-title{
  display: grid;
  grid-column: 1 / 4;
  grid-row: 1;
  font-size: 1.5rem;
}

.event-card-date{
  display: grid;
  text-align: center;
  font-size: 1.2rem;
  align-items: center;
  grid-column:  4/7;
  grid-row: 1;
}

.event-card-desc{
  display: grid;
  align-items: center;
  font-size: 1rem;
  grid-column: 1/7;
  grid-row: 2/5;
}

.event-card-price{
  display: grid;
  grid-row: 5/7;
  grid-column: 1/3;
}

.event-card-email-link:hover{
  text-decoration: none;
}

.event-card-email{
  outline: none;
  place-items: center;
  text-align: center;
  background-color: black;
  color: white;
  white-space: nowrap;
  font-size: 1.2rem;
  border: none;
  /* height: 50px; */
  padding: 0 10%;
}
.event-card-email-link{
  justify-self: center;
  display: grid;
  grid-column: 4/7;
  grid-row: 5/7;
  text-decoration: none;
}

.price-title{
  text-decoration: underline;
  font-size: 1.2rem;
}

.price-value{
  font-size: 1.1rem;
}

@media only screen and (max-width: 400px){
  .event-card-title{
    font-size: 1.3rem;
  }

  .event-card-date{
    font-size: 1rem;
  }
  .event-card-desc{
    font-size: 0.8rem;
  }
  .price-title{
    font-size: 1rem;
  }
  .price-value{
    font-size: 0.9rem;
  }
  .event-card-email{
    font-size: 1rem;
  }
}

@media only screen and (max-width: 343px){
  .event-card-title{
    font-size: 1rem;
  }

  .event-card-date{
    font-size: 0.7rem;
  }
  .event-card-desc{
    font-size: 0.5rem;
  }
  .price-title{
    font-size: 0.7rem;
  }
  .price-value{
    font-size: 0.6rem;
  }
  .event-card-email{
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 599px){
  .event-card-title{
    font-size: 1.8rem;
  }

  .event-card-date{
    font-size: 1.5rem;
  }

  .event-card-desc{
    font-size: 1.2rem;
  }

  .price-title{
    font-size: 1.3rem;
  }

  .price-value{
    font-size: 1.2rem;
  }

  .event-card-email{
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 799px){
  .event-card{
    width: 60vw;
  }

  .event-card-title{
    font-size: 2rem;
  }

  .event-card-date{
    font-size: 1.7rem;
  }

  .event-card-desc{
    font-size: 1.4rem;
  }

  .price-title{
    font-size: 1.5rem;
  }

  .price-value{
    font-size: 1.4rem;
  }

  .event-card-email{
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 1300px){
  .event-card{
    width: 40vw;
  }
}

#galerie {
  background-color: #660033;
}

.galerie-title {
  /* text-align: center;
  font-size: 3rem;
  padding-top: 15%;
  padding-bottom: 15%; */
}

.galerie-upper {
  align-items: center;
  display: flex;
  width: 100vw;
  padding: 0;
  margin: 0;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.galerie-upper img {
  max-width: 90vw;
  height: auto;
  margin-bottom: 3%;
}

.galerie-lower{
  display: grid;
  place-items: center;
}

.galerie-lower .carousel{
  width: 90vw;
}

@media only screen and (min-width: 600px){
  .galerie-upper img{
    width: 45vw;
  }

  .galerie-lower .carousel{
    width: 70vw;
  }
}

@media only screen and (min-width: 1300px){
  .galerie-lower .carousel{
    width: 50vw;
  }
}


@media only screen and (min-width: 1400px){
  .galerie-upper img{
    width: 24vw;
  }
  
  .galerie-lower .carousel{
    width: 40vw;
  }
}

/* phone */
#about {
  background-color: #660033;
}

.text-parent {
  display: grid;
  place-items: center;
  margin: 0 3%;
  padding: 5% 5%;
  background-color: rgba(0, 0, 0, 0.55);
}

.about-text {
  font-size: 1rem;
}

.about-text-paragraph {
  text-align: left;
  font-size: 1.4rem;
  font-weight: 800;
  margin-bottom: 1%;
  margin-top: 2%;
}
/* on rotate - phone */
@media only screen and (max-height: 420px) and (max-width: 830px) {
  .text-parent {
    margin: 0 1%;
    padding: 2% 2%;
  }
}

/* on rotate - bigger phone */
@media only screen and (min-height: 375px) and (max-height: 420px) and (min-width: 775px) and (max-width: 896px) {
}

/*  tablet  */
@media only screen and (min-width: 420px) and (max-width: 1400px) and (min-height: 831px) and (max-height: 1400px) {
  .about-title {
    font-size: 4rem;
  }

  .about-text {
    font-size: 1.5rem;
  }
}

/* on rotate - tablet */
@media only screen and (min-height: 421px) and (max-height: 1050px) and (min-width: 831px) and (max-width: 1400px) {
  .about-title {
    font-size: 4rem;
    padding-top: 10%;
    padding-bottom: 10%;
  }

  .about-text {
    font-size: 1.5rem;
  }
}

/* else (laptop, monitor) */
@media only screen and (min-width: 1401px) {
  .text-parent {
    padding: 2% 2%;
  }

  .about-text {
    font-size: 1.5rem;
  }
}

/* unnormal screen (glaxy fold) */
@media only screen and (max-height: 280px) and (max-width: 653px) {
  .about-text {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 157px) {
  .about-title {
    font-size: 1.5rem;
  }
}

/* phone */
#welcome {
  background: url(/images/fokep_phone.jpg) no-repeat center;
  background-size: cover;
  height: 50vh;
  width: 100vw;
  padding-bottom: 0;
}

#welcome2 {
  display: grid;
  place-items: center;
  height: 50vh;
  background-color: #660033;
  padding-bottom: 0;
}
/* .welcome-image {
  width: 100vw;
  height: 50vh;
} */

.welcome-title {
  /* padding: 45% 0; */
  text-align: center;
  font-size: 2rem;
}

/* on rotate - phone */
@media only screen and (max-height: 420px) and (max-width: 830px) {
  #welcome {
    background: url(/images/fokep_phone.jpg) no-repeat top;
    background-size: cover;
    min-height: 120vh;
  }

  #welcome2 {
    display: grid;
    place-items: center;
    height: 50vh;
    background-color: #660033;
  }

  .welcome-title {
    padding-top: 5%;
    font-size: 2rem;
  }
}

/* on rotate - bigger phone */
@media only screen and (min-height: 375px) and (max-height: 420px) and (min-width: 775px) and (max-width: 896px) {
  #welcome {
    background: url(/images/fokep_phone.jpg) no-repeat top;
    background-size: cover;
  }
}

/*  tablet  */
@media only screen and (min-width: 420px) and (max-width: 1400px) and (min-height: 831px) and (max-height: 1400px) {
  #welcome {
    background: url(/images/fokep_phone.jpg) no-repeat center;
    background-size: cover;
  }

  .welcome-title {
    font-size: 4.5rem;
  }
}

/* on rotate - tablet */
@media only screen and (min-height: 421px) and (max-height: 1050px) and (min-width: 831px) and (max-width: 1400px) {
  #welcome {
    background: url(/images/fokep_phone.jpg) no-repeat;
    background-size: cover;
    background-position: 50% 25%;
    height: 100vh;
  }

  #welcome2 {
    display: grid;
    place-items: center;
    height: 50vh;
    background-color: #660033;
  }

  .welcome-title {
    font-size: 3.5rem;
  }
}

/* else (laptop, monitor) */
@media only screen and (min-width: 1401px) {
  #welcome {
    height: 100vh;
    background: url(/images/fokep.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 30%;
  }

  .welcome-title {
    font-size: 5rem;
  }
}

@media only screen and (max-height: 370px) {
  .welcome-title {
    font-size: 1rem;
  }
}

#trainings {
  background-color: black;
}

.trainings-content {
  display: grid;
}

.training-card {
  margin: 10px 10px 0 10px;
}

.training-card-content {
  background-size: "cover";
  position: relative;
  width: 360px;
  height: 250px;
}

.training-card-content-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* .training-card-content div {
  position: absolute;
  display: grid;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 70vw;
  height: 40vh;
  padding-left: 3vw;
  color: white;
  margin: 0;
} */

.training-card-content-desc {
  grid-row: 2;
  align-self: left;
  font-size: 0.8rem;
  z-index: 1;
}

.training-card-content-date,
.training-card-content-trainer,
.training-card-content-contact {
  font-size: 0.8rem;
}

.card-content-desc-date {
  opacity: 0;
}

.card-content-desc-date span {
  margin-top: 1%;
}

.training-card-content-date strong {
  text-decoration: underline;
}
.training-card-content-contact strong {
  text-decoration: underline;
}
.training-card-content-trainer strong {
  text-decoration: underline;
}

.training-card-content-contact a {
  text-decoration: none;
  color: white;
  pointer-events: none;
}

.training-card-title {
  margin-top: 2%;
  font-size: 1.5rem;
}

.left-card {
  justify-self: start;
}

.right-card {
  justify-self: end;
}

/* .visible {
  opacity: 1;
} */

/* .invisible {
  opacity: 0;
  visibility: visible !important;
} */

/* .disable-blur {
  filter: blur(0);
} */

.card-content-desc-date {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  align-items: left;
  justify-content: center;
  flex-direction: column;
}

.training-card-content:hover .training-card-content-background {
  transition: all 300ms ease-in-out;
  -webkit-filter: blur(10px) opacity(0.2);
  -moz-filter: blur(10px) opacity(0.2);
  -ms-filter: blur(10px) opacity(0.2);
  -o-filter: blur(10px) opacity(0.2);
}

.training-card-content:hover .card-content-desc-date {
  opacity: 1;
}

/* .training-card-content:hover .invisible {
  opacity: 1;
} */

.training-card-content:hover .training-card-content-contact a {
  pointer-events: all;
}

/* .blur-image {
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-filter: blur(10px) opacity(0.2);
  -moz-filter: blur(10px) opacity(0.2);
  -ms-filter: blur(10px) opacity(0.2);
  -o-filter: blur(10px) opacity(0.2);
} */

@media only screen and (min-width: 410px) {
  .training-card-content-desc {
    font-size: 1rem;
  }

  .training-card-content-date,
  .training-card-content-trainer,
  .training-card-content-contact {
    font-size: 1rem;
  }

  .training-card-title {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 750px) {
  .training-card-content-desc {
    font-size: 1.2rem;
  }

  .training-card-content-date,
  .training-card-content-trainer,
  .training-card-content-contact {
    font-size: 1rem;
  }

  .training-card-content {
    width: 600px;
    height: 300px;
  }
}

@media only screen and (min-width: 1025px) {
  .training-card-content-desc {
    font-size: 1.3rem;
  }

  .training-card-content-date,
  .training-card-content-trainer,
  .training-card-content-contact {
    font-size: 1.3rem;
  }

  .training-card-title {
    font-size: 2rem;
    margin-bottom: 2%;
  }

  /* @media (any-hover: hover) {
    .training-card-content:hover .training-card-content-background {
      -webkit-transition: all 300ms ease-in-out;
      -o-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
      -webkit-filter: blur(10px) opacity(0.2);
      -moz-filter: blur(10px) opacity(0.2);
      -ms-filter: blur(10px) opacity(0.2);
      -o-filter: blur(10px) opacity(0.2);
    }

    .training-card-content:hover .card-content-desc-date {
      opacity: 1;
    }

    .training-card-content:hover .invisible {
      opacity: 1;
    }

    .training-card-content:hover .training-card-content-contact a {
      pointer-events: all;
    }
  } */

  .trainings-content {
    grid-template-columns: repeat(2, auto);
    /* row-gap: 3%; */
  }

  .training-card-content {
    width: 500px;
    height: 300px;
  }

  .left-card {
    justify-self: center;
  }

  .right-card {
    justify-self: center;
  }
}

@media only screen and (min-width: 1500px) {
  .training-card-content {
    width: 700px;
    height: 400px;
  }
}

@media only screen and (max-width: 350px) {
  .training-card-title {
    font-size: 1.2rem;
  }

  .training-card-content-desc {
    font-size: 0.6rem;
  }

  .training-card-content-date,
  .training-card-content-trainer,
  .training-card-content-contact {
    font-size: 0.6rem;
  }

  .training-card-content {
    width: 250px;
    height: 150px;
  }
}

@media only screen and (max-width: 255px) {
  .training-card-title {
    font-size: 1rem;
  }

  .training-card-content-desc {
    font-size: 0.4rem;
  }
  .training-card-content-date,
  .training-card-content-trainer,
  .training-card-content-contact {
    font-size: 0.4rem;
  }

  .training-card-content {
    width: 100vw;
  }
}

nav {
  display: flex;
  position: fixed;
  min-height: 3vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
}

.non-trasparent {
  background-color: white;
}

.logo-title {
  display: flex;
}

.nav-logo {
  /* display: flex; */
  height: 40px;
  padding: 0 5px 0 2px;
}

.nav-list {
  display: none;
  list-style: none;
  flex-direction: column;
  width: 100vw;
}

.nav-list.show {
  display: flex;
  text-align: center;
  margin: 3rem;
  z-index: -1;
  position: relative;
}

.nav-list.show li {
  padding: 5px;
  width: 100%;
  font-size: 1rem;
}

.nav-list.show li a:hover {
  cursor: pointer;
  /* background-color: rgba(204, 204, 204, 0.493); */
  text-decoration: underline;
  border-radius: 12px;
}

.hamburger-menu {
  display: flex;
  cursor: pointer;
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  font-size: 2rem;
  touch-action: manipulation;
}

.hamburger-menu svg:hover {
  color: rgba(0, 0, 0, 0.5);
}

.nav-title {
  /* display: flex; */
  font-size: 2rem;
  padding-bottom: 5px;
  letter-spacing: 1px;
  color: #660033;
  font-family: "Lobster", cursive !important;
}

.nav-title a {
  font-family: "Lobster", cursive !important;
  text-decoration: none;
  color: #660033;
  letter-spacing: 0;
}

.nav-list a {
  text-decoration: none;
  color: black;
}

@media only screen and (max-width: 335px) {
  .nav-title {
    display: none;
  }
}

@media only screen and (max-width: 1665px) and (min-width: 700px) {
  .nav-list.show li {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 1665px) {
  .nav-list.show li {
    font-size: 2rem;
  }

  .nav-title {
    font-size: 3rem;
  }

  .nav-logo {
    height: 65px;
  }
}

@media only screen and (max-height: 376px) {
  .nav-list {
    max-height: 60vh;
    overflow-y: scroll;
  }
}

#footer {
  height: 50vh;
  display: grid;
  place-items: center;
  background-color: #292929;
}

.footer-title {
  text-align: center;
  font-size: 1.2rem;
  padding: auto;
  font-family: serif !important;
}

.footer-title a {
  font-family: serif !important;
  color: white;
  text-decoration: none;
}

.footer-content {
  margin: auto;
  display: grid;
  width: 100%;
  height: auto;
  grid-column-gap: 1%;
  -webkit-column-gap: 1%;
          column-gap: 1%;
  /* padding: 0 8%; */
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  /* place-items: center; */
}

.footer-all-link {
  grid-column: 1;
  grid-row: 1/4;
  font-family: serif !important;
}

.footer-all-us {
  grid-column: 2;
  grid-row: 1;
  font-family: serif !important;
}

.footer-all-social {
  grid-column: 2;
  grid-row: 3;
}

.footer-all-social a {
  text-decoration: none;
  color: white;
}

.footer-all-social a:hover {
  text-decoration: underline;
}

.footer-all-link a {
  text-decoration: none;
  color: white;
}

.footer-all-link a:hover {
  text-decoration: underline;
}

#footer h4 {
  font-size: 0.8rem;
  font-family: serif !important;
}

#footer li {
  list-style: none;
  font-size: 0.6rem;
  padding-left: 5%;
  width: 100%;
  font-family: serif !important;
}

#footer a {
  font-family: serif !important;
}

#footer li::before {
  content: "-";
  padding-right: 3%;
}

/*  tablet  */
@media only screen and (min-width: 420px) and (max-width: 1400px) and (min-height: 897px) and (max-height: 1400px) {
  .footer-title {
    font-size: 2rem;
  }

  #footer h4 {
    font-size: 1.2rem;
  }

  #footer li {
    font-size: 1rem;
  }
}

/* on rotate - tablet */
@media only screen and (min-height: 421px) and (max-height: 1050px) and (min-width: 897px) and (max-width: 1400px) {
  .footer-title {
    font-size: 2rem;
  }

  #footer h4 {
    font-size: 1.2rem;
  }

  #footer li {
    font-size: 1rem;
  }
}

/* else (laptop, monitor) */
@media only screen and (min-width: 1401px) {
  #footer {
    height: 50vh;
    display: grid;
    place-items: center;
    background-color: #292929;
  }

  .footer-title {
    text-align: center;
    font-size: 2rem;
    padding: auto;
  }

  .footer-content {
    margin: auto;
    display: grid;
    width: 100%;
    height: auto;
    padding: 0 8%;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
    /* place-items: center; */
  }

  .footer-all-link {
    grid-column: 1;
    grid-row: 1/4;
  }

  .footer-all-us {
    grid-column: 2;
    grid-row: 1;
  }

  .footer-all-social {
    grid-column: 2;
    grid-row: 3;
  }

  .footer-all-social a {
    text-decoration: none;
    color: white;
  }

  .footer-all-social a:hover {
    text-decoration: underline;
  }

  .footer-all-link a {
    text-decoration: none;
    color: white;
  }

  .footer-all-link a:hover {
    text-decoration: underline;
  }

  #footer h4 {
    font-size: 1.5rem;
  }

  #footer li {
    list-style: none;
    font-size: 1.2rem;
    padding-left: 5%;
    width: 100%;
  }

  #footer li::before {
    content: "-";
    padding-right: 3%;
  }
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.main {
  color: black;
  margin: auto;
}

.main-link {
  text-decoration: none;
  color: #660033;
}

.main-link:hover {
  text-decoration: underline;
}

