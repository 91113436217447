#event {
  background-color: #660033;
  
}

.event-container{
  display: grid;
  place-items: center;
  grid-template-columns: auto;
}

.event-card{
  border-top: 1px solid white;
  border-right: 1px solid white;
  width: 90vw;
  padding: 2% 1%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 0.5fr);
  margin-bottom: 2%;
}

.event-card-title{
  display: grid;
  grid-column: 1 / 4;
  grid-row: 1;
  font-size: 1.5rem;
}

.event-card-date{
  display: grid;
  text-align: center;
  font-size: 1.2rem;
  align-items: center;
  grid-column:  4/7;
  grid-row: 1;
}

.event-card-desc{
  display: grid;
  align-items: center;
  font-size: 1rem;
  grid-column: 1/7;
  grid-row: 2/5;
}

.event-card-price{
  display: grid;
  grid-row: 5/7;
  grid-column: 1/3;
}

.event-card-email-link:hover{
  text-decoration: none;
}

.event-card-email{
  outline: none;
  place-items: center;
  text-align: center;
  background-color: black;
  color: white;
  white-space: nowrap;
  font-size: 1.2rem;
  border: none;
  /* height: 50px; */
  padding: 0 10%;
}
.event-card-email-link{
  justify-self: center;
  display: grid;
  grid-column: 4/7;
  grid-row: 5/7;
  text-decoration: none;
}

.price-title{
  text-decoration: underline;
  font-size: 1.2rem;
}

.price-value{
  font-size: 1.1rem;
}

@media only screen and (max-width: 400px){
  .event-card-title{
    font-size: 1.3rem;
  }

  .event-card-date{
    font-size: 1rem;
  }
  .event-card-desc{
    font-size: 0.8rem;
  }
  .price-title{
    font-size: 1rem;
  }
  .price-value{
    font-size: 0.9rem;
  }
  .event-card-email{
    font-size: 1rem;
  }
}

@media only screen and (max-width: 343px){
  .event-card-title{
    font-size: 1rem;
  }

  .event-card-date{
    font-size: 0.7rem;
  }
  .event-card-desc{
    font-size: 0.5rem;
  }
  .price-title{
    font-size: 0.7rem;
  }
  .price-value{
    font-size: 0.6rem;
  }
  .event-card-email{
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 599px){
  .event-card-title{
    font-size: 1.8rem;
  }

  .event-card-date{
    font-size: 1.5rem;
  }

  .event-card-desc{
    font-size: 1.2rem;
  }

  .price-title{
    font-size: 1.3rem;
  }

  .price-value{
    font-size: 1.2rem;
  }

  .event-card-email{
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 799px){
  .event-card{
    width: 60vw;
  }

  .event-card-title{
    font-size: 2rem;
  }

  .event-card-date{
    font-size: 1.7rem;
  }

  .event-card-desc{
    font-size: 1.4rem;
  }

  .price-title{
    font-size: 1.5rem;
  }

  .price-value{
    font-size: 1.4rem;
  }

  .event-card-email{
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 1300px){
  .event-card{
    width: 40vw;
  }
}
