#trainings {
  background-color: black;
}

.trainings-content {
  display: grid;
}

.training-card {
  margin: 10px 10px 0 10px;
}

.training-card-content {
  background-size: "cover";
  position: relative;
  width: 360px;
  height: 250px;
}

.training-card-content-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* .training-card-content div {
  position: absolute;
  display: grid;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 70vw;
  height: 40vh;
  padding-left: 3vw;
  color: white;
  margin: 0;
} */

.training-card-content-desc {
  grid-row: 2;
  align-self: left;
  font-size: 0.8rem;
  z-index: 1;
}

.training-card-content-date,
.training-card-content-trainer,
.training-card-content-contact {
  font-size: 0.8rem;
}

.card-content-desc-date {
  opacity: 0;
}

.card-content-desc-date span {
  margin-top: 1%;
}

.training-card-content-date strong {
  text-decoration: underline;
}
.training-card-content-contact strong {
  text-decoration: underline;
}
.training-card-content-trainer strong {
  text-decoration: underline;
}

.training-card-content-contact a {
  text-decoration: none;
  color: white;
  pointer-events: none;
}

.training-card-title {
  margin-top: 2%;
  font-size: 1.5rem;
}

.left-card {
  justify-self: start;
}

.right-card {
  justify-self: end;
}

/* .visible {
  opacity: 1;
} */

/* .invisible {
  opacity: 0;
  visibility: visible !important;
} */

/* .disable-blur {
  filter: blur(0);
} */

.card-content-desc-date {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  align-items: left;
  justify-content: center;
  flex-direction: column;
}

.training-card-content:hover .training-card-content-background {
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-filter: blur(10px) opacity(0.2);
  -moz-filter: blur(10px) opacity(0.2);
  -ms-filter: blur(10px) opacity(0.2);
  -o-filter: blur(10px) opacity(0.2);
}

.training-card-content:hover .card-content-desc-date {
  opacity: 1;
}

/* .training-card-content:hover .invisible {
  opacity: 1;
} */

.training-card-content:hover .training-card-content-contact a {
  pointer-events: all;
}

/* .blur-image {
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-filter: blur(10px) opacity(0.2);
  -moz-filter: blur(10px) opacity(0.2);
  -ms-filter: blur(10px) opacity(0.2);
  -o-filter: blur(10px) opacity(0.2);
} */

@media only screen and (min-width: 410px) {
  .training-card-content-desc {
    font-size: 1rem;
  }

  .training-card-content-date,
  .training-card-content-trainer,
  .training-card-content-contact {
    font-size: 1rem;
  }

  .training-card-title {
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 750px) {
  .training-card-content-desc {
    font-size: 1.2rem;
  }

  .training-card-content-date,
  .training-card-content-trainer,
  .training-card-content-contact {
    font-size: 1rem;
  }

  .training-card-content {
    width: 600px;
    height: 300px;
  }
}

@media only screen and (min-width: 1025px) {
  .training-card-content-desc {
    font-size: 1.3rem;
  }

  .training-card-content-date,
  .training-card-content-trainer,
  .training-card-content-contact {
    font-size: 1.3rem;
  }

  .training-card-title {
    font-size: 2rem;
    margin-bottom: 2%;
  }

  /* @media (any-hover: hover) {
    .training-card-content:hover .training-card-content-background {
      -webkit-transition: all 300ms ease-in-out;
      -o-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
      -webkit-filter: blur(10px) opacity(0.2);
      -moz-filter: blur(10px) opacity(0.2);
      -ms-filter: blur(10px) opacity(0.2);
      -o-filter: blur(10px) opacity(0.2);
    }

    .training-card-content:hover .card-content-desc-date {
      opacity: 1;
    }

    .training-card-content:hover .invisible {
      opacity: 1;
    }

    .training-card-content:hover .training-card-content-contact a {
      pointer-events: all;
    }
  } */

  .trainings-content {
    grid-template-columns: repeat(2, auto);
    /* row-gap: 3%; */
  }

  .training-card-content {
    width: 500px;
    height: 300px;
  }

  .left-card {
    justify-self: center;
  }

  .right-card {
    justify-self: center;
  }
}

@media only screen and (min-width: 1500px) {
  .training-card-content {
    width: 700px;
    height: 400px;
  }
}

@media only screen and (max-width: 350px) {
  .training-card-title {
    font-size: 1.2rem;
  }

  .training-card-content-desc {
    font-size: 0.6rem;
  }

  .training-card-content-date,
  .training-card-content-trainer,
  .training-card-content-contact {
    font-size: 0.6rem;
  }

  .training-card-content {
    width: 250px;
    height: 150px;
  }
}

@media only screen and (max-width: 255px) {
  .training-card-title {
    font-size: 1rem;
  }

  .training-card-content-desc {
    font-size: 0.4rem;
  }
  .training-card-content-date,
  .training-card-content-trainer,
  .training-card-content-contact {
    font-size: 0.4rem;
  }

  .training-card-content {
    width: 100vw;
  }
}
