@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif !important;
}

html,
body {
  color: white !important;
  /* overflow-x: hidden; */
}

section {
  padding-bottom: 10%;
}

.section-titles {
  font-size: 2.5rem;
  padding-top: 15%;
  padding-bottom: 5%;
  text-align: center;
}

@media only screen and (max-width: 222px) {
  .section-titles {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 166px) {
  .section-titles {
    font-size: 1.6rem;
  }

  body {
    overflow-x: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .section-titles {
    font-size: 2.8rem;
  }
}

@media only screen and (min-width: 1400px) {
  .section-titles {
    font-size: 3rem;
  }
}

@media only screen and (min-width: 1600px) {
  .section-titles {
    font-size: 3.5rem;
  }
}
