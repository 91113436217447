@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

nav {
  display: flex;
  position: fixed;
  min-height: 3vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
}

.non-trasparent {
  background-color: white;
}

.logo-title {
  display: flex;
}

.nav-logo {
  /* display: flex; */
  height: 40px;
  padding: 0 5px 0 2px;
}

.nav-list {
  display: none;
  list-style: none;
  flex-direction: column;
  width: 100vw;
}

.nav-list.show {
  display: flex;
  text-align: center;
  margin: 3rem;
  z-index: -1;
  position: relative;
}

.nav-list.show li {
  padding: 5px;
  width: 100%;
  font-size: 1rem;
}

.nav-list.show li a:hover {
  cursor: pointer;
  /* background-color: rgba(204, 204, 204, 0.493); */
  text-decoration: underline;
  border-radius: 12px;
}

.hamburger-menu {
  display: flex;
  cursor: pointer;
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  font-size: 2rem;
  touch-action: manipulation;
}

.hamburger-menu svg:hover {
  color: rgba(0, 0, 0, 0.5);
}

.nav-title {
  /* display: flex; */
  font-size: 2rem;
  padding-bottom: 5px;
  letter-spacing: 1px;
  color: #660033;
  font-family: "Lobster", cursive !important;
}

.nav-title a {
  font-family: "Lobster", cursive !important;
  text-decoration: none;
  color: #660033;
  letter-spacing: 0;
}

.nav-list a {
  text-decoration: none;
  color: black;
}

@media only screen and (max-width: 335px) {
  .nav-title {
    display: none;
  }
}

@media only screen and (max-width: 1665px) and (min-width: 700px) {
  .nav-list.show li {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 1665px) {
  .nav-list.show li {
    font-size: 2rem;
  }

  .nav-title {
    font-size: 3rem;
  }

  .nav-logo {
    height: 65px;
  }
}

@media only screen and (max-height: 376px) {
  .nav-list {
    max-height: 60vh;
    overflow-y: scroll;
  }
}
