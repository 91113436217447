#galerie {
  background-color: #660033;
}

.galerie-title {
  /* text-align: center;
  font-size: 3rem;
  padding-top: 15%;
  padding-bottom: 15%; */
}

.galerie-upper {
  align-items: center;
  display: flex;
  width: 100vw;
  padding: 0;
  margin: 0;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.galerie-upper img {
  max-width: 90vw;
  height: auto;
  margin-bottom: 3%;
}

.galerie-lower{
  display: grid;
  place-items: center;
}

.galerie-lower .carousel{
  width: 90vw;
}

@media only screen and (min-width: 600px){
  .galerie-upper img{
    width: 45vw;
  }

  .galerie-lower .carousel{
    width: 70vw;
  }
}

@media only screen and (min-width: 1300px){
  .galerie-lower .carousel{
    width: 50vw;
  }
}


@media only screen and (min-width: 1400px){
  .galerie-upper img{
    width: 24vw;
  }
  
  .galerie-lower .carousel{
    width: 40vw;
  }
}
