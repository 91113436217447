.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.main {
  color: black;
  margin: auto;
}

.main-link {
  text-decoration: none;
  color: #660033;
}

.main-link:hover {
  text-decoration: underline;
}
