#footer {
  height: 50vh;
  display: grid;
  place-items: center;
  background-color: #292929;
}

.footer-title {
  text-align: center;
  font-size: 1.2rem;
  padding: auto;
  font-family: serif !important;
}

.footer-title a {
  font-family: serif !important;
  color: white;
  text-decoration: none;
}

.footer-content {
  margin: auto;
  display: grid;
  width: 100%;
  height: auto;
  column-gap: 1%;
  /* padding: 0 8%; */
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  /* place-items: center; */
}

.footer-all-link {
  grid-column: 1;
  grid-row: 1/4;
  font-family: serif !important;
}

.footer-all-us {
  grid-column: 2;
  grid-row: 1;
  font-family: serif !important;
}

.footer-all-social {
  grid-column: 2;
  grid-row: 3;
}

.footer-all-social a {
  text-decoration: none;
  color: white;
}

.footer-all-social a:hover {
  text-decoration: underline;
}

.footer-all-link a {
  text-decoration: none;
  color: white;
}

.footer-all-link a:hover {
  text-decoration: underline;
}

#footer h4 {
  font-size: 0.8rem;
  font-family: serif !important;
}

#footer li {
  list-style: none;
  font-size: 0.6rem;
  padding-left: 5%;
  width: 100%;
  font-family: serif !important;
}

#footer a {
  font-family: serif !important;
}

#footer li::before {
  content: "-";
  padding-right: 3%;
}

/*  tablet  */
@media only screen and (min-width: 420px) and (max-width: 1400px) and (min-height: 897px) and (max-height: 1400px) {
  .footer-title {
    font-size: 2rem;
  }

  #footer h4 {
    font-size: 1.2rem;
  }

  #footer li {
    font-size: 1rem;
  }
}

/* on rotate - tablet */
@media only screen and (min-height: 421px) and (max-height: 1050px) and (min-width: 897px) and (max-width: 1400px) {
  .footer-title {
    font-size: 2rem;
  }

  #footer h4 {
    font-size: 1.2rem;
  }

  #footer li {
    font-size: 1rem;
  }
}

/* else (laptop, monitor) */
@media only screen and (min-width: 1401px) {
  #footer {
    height: 50vh;
    display: grid;
    place-items: center;
    background-color: #292929;
  }

  .footer-title {
    text-align: center;
    font-size: 2rem;
    padding: auto;
  }

  .footer-content {
    margin: auto;
    display: grid;
    width: 100%;
    height: auto;
    padding: 0 8%;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
    /* place-items: center; */
  }

  .footer-all-link {
    grid-column: 1;
    grid-row: 1/4;
  }

  .footer-all-us {
    grid-column: 2;
    grid-row: 1;
  }

  .footer-all-social {
    grid-column: 2;
    grid-row: 3;
  }

  .footer-all-social a {
    text-decoration: none;
    color: white;
  }

  .footer-all-social a:hover {
    text-decoration: underline;
  }

  .footer-all-link a {
    text-decoration: none;
    color: white;
  }

  .footer-all-link a:hover {
    text-decoration: underline;
  }

  #footer h4 {
    font-size: 1.5rem;
  }

  #footer li {
    list-style: none;
    font-size: 1.2rem;
    padding-left: 5%;
    width: 100%;
  }

  #footer li::before {
    content: "-";
    padding-right: 3%;
  }
}
